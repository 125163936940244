import React from "react";
import PropTypes from "prop-types";
import { useHistory, /* useLocation, */ Link } from "react-router-dom";
import { inject } from "mobx-react";
import uiConfig from "../../uiConfig";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  support: {
    marginBottom: 0,
    minWidth: 210,
    marginLeft: 15,
  },
}));

const AccountDialog = ({ user, isLoggingIn }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleClose = () => {
    history.goBack();
  }

  return (
    <Dialog open onClose={handleClose} aria-labelledby="account-dialog-title" fullWidth disableScrollLock>
      <DialogTitle id="account-dialog-title">Account</DialogTitle>
      <DialogContent>
        {!isLoggingIn ?
          <>
            <Typography>{user.firstName} {user.lastName}</Typography>
            <Typography gutterBottom>{user.email}</Typography>
            <br />
            {user.role === "manager" &&
              <Button color="primary" component={Link} to="/admin" variant="contained">
                Manage Portal
              </Button>
            }
          </> :
          <CircularProgress />
        }
      </DialogContent>
      <DialogActions>
        <Typography className={classes.support} variant="body2">Support: <MuiLink href={`mailto:${uiConfig.supportEmail}`}>{uiConfig.supportEmail}</MuiLink></Typography>
        <div style={{ width: '100%' }} />
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountDialog.propTypes = {
  isLoggingIn: PropTypes.bool,
};

export default inject(({ store }, props) => ({
  isLoggingIn: store.isLoggingIn, user: store.currentUser,
}))(AccountDialog);
