const config = {
  CMEfilter: false,
  mainColor: "#138F6A",
  primaryContrastText: "#fff",
  secondaryColor: "#8F1338",
  secondaryContrastText: "#fff",
  searchPlaceholder: "What do you want to learn?",
  loginText: "",
  requireAuth: true,
  showOnDemand: false,
  showBuy: false,
  showSignUp: false,
  showSponsors: false,
  showSponsorsPage: false,
  sponsorsPageCards: false,
  sponsorsCardsHorizontal: false,
  showEvents: false,
  eventsDebugMode: false,
  showProducts: false,
  showSchedule: false,
  trackPageScrollOffset: -80,
  showExhibitHall: false,
  showChat: false,
  enablePlaybackTracking: true,
  showPrivacyPolicy: false,
  // externalPrivacyPolicyUrl: "//www.ya.ru",
  // acceptPrivacyPolicyText: "Accept <Link>",
  emailHelperText: "",
  showSessionEvaluation: false,
  showRecordingEvaluation: true,
  showAskSpeaker: false,
  supportEmail: "info@slidespiel.com",
  portals: [
    { 
      year: 2020,
      logo: 'logo.png',
      signInRedirect: "/certificates",
      trackImages: false,
      home: {
        minHeight: 500,
        height: '0vh',
        paperMarginType: 'Right',
        paperMargin: '0vw',
        paperMaxWidth: 800,
        paperColor: 'rgba(0,0,0,.3)',
        backgroundUrl: 'background.png',
        backgroundPosition: { x: 50, y: 40 },
        backgroundRatio: 0.2549,
        justifyContent: 'center',
        alignItems: 'center',
        color: "#fff",
        buttonColor: "secondary",
        getLink: "/login",
        showHero: true,
        showCta: false,
        showCtaText: false,
        ctaPadding: [2, 4],
        //fontFamily: "Fira Sans",
        //fontWeight: 'bold',
      },
    },
  ],
  main: {
    logoMarginTop: 5,
    logoPadding: 0,
    logoHeight: 70,
    logoHeightXs: 50,
    progressBarTop: 79,
    progressBarTopXs: 55,
    childrenMarginTop: 79,
    childrenMarginTopXs: 50,
    searchColor: "primary",
    barColor: "default",
    barTextColor: "primary",
    barButtonColor: "primary"
  },
  footer: {
    // facebookUrl: "https://www.facebook.com/",
    // twitterUrl: "https://twitter.com/",
    // instagramUrl: "https://instagram.com/",
    // linkedInUrl: "https://www.linkedin.com/",
  },
  eventHome: {
    titleColor: "primary",
    subtitleColor: "secondary",
    liveColor: "secondary",
    registerColor: "primary",
    exhibitHallColor: "secondary",
    networkingRoomColor: "primary",
  }
};

export default config;
