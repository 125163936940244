import React, { /* useEffect, */ useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import api from "../../api";
// import config from '../../config';
import uiConfig from '../../uiConfig';
import Button from '@material-ui/core/Button';
import { useFeedback } from "../feedback/Service";
import { inject } from "mobx-react";
import EvaluationDialog from "./EvaluationDialog";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseButton from "../CloseButton";
import { useHistory, useLocation } from "react-router";
import DialogTitle from "@material-ui/core/DialogTitle";

// const SEND_INTERVAL = 30 * 1000;

const useStyles = makeStyles(theme => ({
  content: {
    padding: "0 !important",
    background: "#000",
  },
  contentFullScreen: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    padding: theme.spacing(1, 2),
    "& > h2": { fontSize: '1rem' }
  }
}));

const RecordingDialog = ({ hide, video, user, portal }) => {
  const [showEvaluationForm, setShowEvaluationForm] = useState(false);
  const ratio = parseFloat(video.ratio);
  const height = 500;
  const feedback = useFeedback();
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleAsk = async () => {
    const question = await feedback.form({ title: "Ask the Speaker", input: { title: 'Your Question', type: "text", name: "text", rows: 5, variant: "outlined" } });
    if (!question)
      return;
    await api.askSpeaker(video.id, question);
    feedback.snackbar({ type: "success", text: "Your question was sent to the speaker. You'll get your reply by email." })
  }

  const handleClose = () => {
    history.push(pathname.replace("/" + pathname.split("/").pop(), ""));
    hide();
  }

  // useEffect(() => {
  //   // console.log("tracker effect", config);
  //   if (!user)
  //     return;
  //   if (!uiConfig.enablePlaybackTracking)
  //     return;
  //   const sendTrackerData = async () => {
  //     await api.postTrackerData({ email: user.email, alias: video.alias });
  //     console.log("tracker");
  //   }
  //   sendTrackerData();
  //   const interval = setInterval(sendTrackerData, SEND_INTERVAL);
  //   return () => {
  //     clearInterval(interval);
  //   }
  // }, [user, video]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let src = `https://slidespiel.com/view/${video.alias}`;
  if (uiConfig.enablePlaybackTracking && user)
    src += `?trackingEmail=${user.email}`;
  return (
    <>
      {uiConfig.showRecordingEvaluation &&
        <EvaluationDialog open={showEvaluationForm} recording={video} handleClose={() => setShowEvaluationForm(false)} entity="recording" />
      }
      <Dialog open onClose={handleClose} maxWidth="md" fullScreen={fullScreen} disableScrollLock>
        <DialogTitle className={classes.title}>{video.name}</DialogTitle>
        {fullScreen && <CloseButton onClick={handleClose} />}
        <DialogContent className={classes.content + " " + (fullScreen ? classes.contentFullScreen : "")}>
          <iframe frameBorder="0" width={fullScreen ? window.innerWidth : height * ratio} height={fullScreen ? window.innerWidth / ratio : height} src={src} title={`Preview recording ${src}`} allowFullScreen />
        </DialogContent>
        {((uiConfig.showRecordingEvaluation || uiConfig.showAskSpeaker) && user) &&
          <DialogActions>
            {uiConfig.showAskSpeaker &&
              <Button color="primary" onClick={handleAsk}>Ask the Speaker</Button>
            }
            {uiConfig.showRecordingEvaluation &&
              <Button color="primary" onClick={() => setShowEvaluationForm(true)}>Evaluate</Button>
            }
          </DialogActions>
        }
      </Dialog>
    </>
  )
}

export default inject(({ store }, props) => ({
  portal: store.portal, store
}))(RecordingDialog);
