import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import usePortal from './hooks/portal';
import config from '../config';
import draftToHtml from 'draftjs-to-html';
import CertIcon from '@material-ui/icons/CheckCircle';

const parseSummary = text => {
  try {
    return draftToHtml(JSON.parse(text));
  }
  catch {
    return text;
  }
}

const useStyles = makeStyles(theme => ({
  card: {
    //maxWidth: 345,
    //minWidth: 300
  },
  media: {
    height: 0,
    //paddingTop: '75%', //'56.25%', // 16:9
    cursor: 'pointer',
    position: 'relative',
  },
  title: {
    fontSize: '1.1rem',
    cursor: 'pointer',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  summary: {
    // maxHeight: 115,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    cursor: 'pointer',
    "& > p": {
      margin: 0,
    }
  },
  speakers: {
    // maxHeight: 100,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    //cursor: 'pointer',
  },
  priceSpan: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: `0 0 ${theme.spacing(16)}px ${theme.spacing(16)}px`,
    borderColor: `transparent transparent ${theme.palette.primary.main} transparent`
  },
  price: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  tooltip: {
    fontSize: '1rem',
    // whiteSpace: 'pre-line',
    // lineHeight: 1.5,
    // maxWidth: 500
  },
  cert: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.success.main,
    background: "rgba(0,0,0,.5)",
    padding: theme.spacing(0.5),
    fontSize: "2rem"
  }
}));

const RecordingCard = ({ recording, openModal, openDetails, user }) => {
  const classes = useStyles();
  const portal = usePortal();
  const { name, speakers, summary, ratio, previewUrl, alias, questionsCount, ceHours, ceTypeName, id } = recording;
  const speakersString = speakers.map((speaker) => speaker.firstName + " " + speaker.lastName).join(", ");
  const link = (questionsCount > 0 && alias) ? portal.urlPrefix + `/quizzes/${recording.id}` : null;
  const cdnUrl = previewUrl.replace('https://slidespielweb.azurewebsites.net/', 'https://sspwebapi.azureedge.net/');
  const imagePadding = (100 / ratio) + '%';
  const history = useHistory();
  const { pathname } = useLocation();
  const titleRef = useRef();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  const isMember = user?.isMember;
  let price = isMember ? recording.priceForMembers : recording.price;

  const handleRecordOpen = () => {
    if (link)
      history.push(link);
    else if (alias) {
      openModal("isRecordOpened", { alias, ratio, id: recording.id, evaluationFormMetaData: recording.evaluationFormMetaData, name });
      history.push(pathname + "/" + id);
    }
    else {
      if (price)
        openModal("isRecordBuyOpened", recording);
      else
        history.push(pathname + "/sign-up");
    }
  }

  useEffect(() => {
    const element = titleRef.current?.children[0]?.children[0];
    const isEllipsisActive = element.offsetHeight < element.scrollHeight;
    // console.log("isEllipsisActive", isEllipsisActive, element.offsetHeight, element.scrollHeight);
    // console.dir(element);
    setIsEllipsisActive(isEllipsisActive);
  }, [recording.title]);

  if (!user && price)
    price = `${recording.priceForMembers} / $${recording.price}`
  const subheader = (config.hasCme && (ceHours || ceTypeName)) && (ceHours ? `${ceHours} ${ceTypeName || ""} Credit(s)` : "No CME");

  return (
    <Card className={classes.card}>
      <Tooltip title={isEllipsisActive ? name : ""} classes={{ tooltip: classes.tooltip }}>
        <div>
          <CardHeader
            title={name}
            subheader={subheader}
            classes={{
              title: classes.title
            }}
            onClick={handleRecordOpen}
            ref={titleRef}
          />
        </div>
      </Tooltip>
      <CardMedia
        className={classes.media}
        image={cdnUrl}
        title={name}
        style={{ paddingTop: imagePadding }}
        onClick={handleRecordOpen}
      >
        {recording.step >= 7 && <Tooltip title="Certificate received" classes={{ tooltip: classes.tooltip }}><CertIcon className={classes.cert} /></Tooltip>}
        {(!recording.alias && !!price) &&
          <>
            <span className={classes.priceSpan} />
            <Typography variant="subtitle1" className={classes.price}>${price}</Typography>
          </>
        }
        {(!recording.alias && !price) &&
          <>
            <span className={classes.priceSpan} />
            <Typography variant="subtitle1" className={classes.price}>No Access</Typography>
          </>
        }
      </CardMedia>
      <CardContent>
        <Typography variant="subtitle2" gutterBottom component="p" className={classes.speakers}>
          {speakersString}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.summary} onClick={openDetails(id)} dangerouslySetInnerHTML={{ __html: parseSummary(summary) }} />
      </CardContent>
    </Card>
  );
}

export default RecordingCard;
